<template>
  <div
    ref="node"
    class="node-item"
    :style="flowNodeContainer"
    @mouseup="changeNodeSite"
  >
    <el-popover
      placement="bottom"
      width="240"
      popper-class="line-proper"
      trigger="hover"
      @show="popoverShow(node)"
    >
      <ul
        v-if="nodeType === 1"
        class="prover-ul"
      >
        <li>
          <span class="prover-label">资产名称：</span>
          <span>{{ nodeInfo.equipmentName }}</span>
        </li>
        <li>
          <span class="prover-label">资产类型：</span>
          <span>{{ nodeInfo.assessTypeName }}</span>
        </li>
        <li>
          <span class="prover-label">IP地址：</span>
          <span>{{ nodeInfo.ipAddress }}</span>
        </li>
        <li>
          <span class="prover-label">物理位置：</span>
          <span>{{ nodeInfo.geographicLocation }}</span>
        </li>
        <li>
          <span class="prover-label">资产描述：</span>
          <span>{{ nodeInfo.description }}</span>
        </li>
      </ul>
      <ul
        v-if="nodeType === 2"
        class="prover-ul"
      >
        <li v-if="nodeType === 2">
          <span class="prover-label">业务名称：</span>
          <span>{{ nodeInfo.equipmentName }}</span>
        </li>
        <li>
          <span class="prover-label">业务描述：</span>
          <span>{{ nodeInfo.description }}</span>
        </li>
      </ul>
      <ul
        v-if="nodeType === 3"
        class="prover-ul"
      >
        <li>
          <span class="prover-label">第三方名称：</span>
          <span>{{ nodeInfo.equipmentName }}</span>
        </li>
        <li>
          <span class="prover-label">隐私政策连接：</span>
          <span>{{ nodeInfo.privacyPolicyUrl }}</span>
        </li>
      </ul>
      <ul
        v-if="nodeType === 5"
        class="prover-ul"
      >
        <li>
          <span class="prover-label">部门名称：</span>
          <span>{{ nodeInfo.equipmentName }}</span>
        </li>
      </ul>
      <div
        slot="reference"
        class="node-con"
      >
        <span
          class="node-lengend"
          :class="'node-lengend-' + node.type"
        />
        <span class="node-name">{{ node.label }}</span>
      </div>
    </el-popover>

  </div>
</template>

<script>
import { GetNodeInfo, SaveNodeLocation } from '@/api/dataMap/dataMapProcess'

export default {
  props: {
    node: Object,
    isconnect: Boolean,
  },
  data() {
    return {
      mouseEnter: false,
      nodeInfo: {
        assets: {},
      },
      nodeType: '',
      thridPartyTypeObj: {
        1: '供应商',
        2: '销售代理及渠道',
        3: '原厂商',
        4: '合作单位',
        5: '监管单位',
        6: '其他',
      },
    }
  },
  computed: {
    // 节点容器样式
    flowNodeContainer: {
      get() {
        return {
          position: 'absolute',
          minWidth: '80px',
          top: this.node.top,
          left: this.node.left,
          boxShadow: this.mouseEnter ? '#66a6e0 0px 0px 12px 0px' : '',
        }
      },
    },
    iconClass() {
      //  1采集 2处理 3存储 4分享 5使用
      const clsObj = {
        1: 'icon-gugeliulanqi',
        2: 'icon-shujuchuli',
        3: 'icon-shujucunchu',
        4: 'icon-tubiaozhizuomoban',
        5: 'icon-shujucunchu',
      }
      return clsObj[this.node.type]
    },
  },
  methods: {
    // 删除节点
    popoverShow() {
      GetNodeInfo(this.node.value).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.nodeType = resData.data.equipmentType
          this.nodeInfo = resData.data
        }
      })
    },
    changeNodeSite() {
      const screenW = window.screen.width
      const submitData = {
        left: `${this.$refs.node.offsetLeft}-${screenW}`,
        top: `${this.$refs.node.offsetTop}`,
        nodeId: this.node.id,
      }
      SaveNodeLocation(submitData).then(res => {
      })
    },
  },
}
</script>
<style lang="scss">
.prover-ul li {
  padding: 0 10px;
  min-height: 32px;
  //line-height: ;
  display: flex;
  align-items: center;
  vertical-align: middle;
  border-bottom: 1px solid #dcdfe6;
  list-style: none;
}
.line-proper {
  padding: 0;
  font-size: 12px;
}
.type-icon{
  //display: block;
}
  .node-item {
    border-radius: 4px;
    box-shadow: 0 0 2px #0d40b2;
    cursor: move;
    //color: #ffffff;
    overflow: initial;
    //background: #0d40b2;
    padding: 0 10px;
    i {
      font-size: 20px;
      color: #0d40b2;
    }
  }

  .node-titel {
    height: 20px;
  }

  .node-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    line-height: 20px
  }

  .node-icon i {
    cursor: pointer;
  }

  .node-con {
    text-align: center;
    //line-height: 30px;
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .node-del {
      position: absolute;
      color: red;
      font-size: 16px;
      cursor: pointer;
      top: -8px;
      right: -8px;
  }
  .flow-node-drag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: crosshair;
  }
.node-lengend{
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;
}
.node-lengend-1{
  background: #7367f0;
  color: #7367f0;
}
.node-lengend-2{
  background: #f3862d;
  color: #f3862d;
}
.node-lengend-3{
  background: #1dce05;
  color: #1dce05;
}
.node-lengend-4{
  background: #00cfe8;
  color: #00cfe8;
}
.node-lengend-5{
  background: #1dce05;
  color: #1dce05;
}
</style>
