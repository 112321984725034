<template>
  <div
    v-loading="loading"
    class="line-wrap"
  >
    <el-form
      :inline="true"
      :model="form"
      class="demo-form-inline"
    >
      <el-form-item label="业务系统">
        <el-select
          v-model="form.productId"
          placeholder="请选择业务系统"
          clearable
          @clear="getPrivateFlowChart"
          @change="productChange"
        >
          <el-option
            v-for="(item,index) in productOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="业务场景">
        <el-select
          v-model="form.sceneId"
          placeholder="请选择业务场景"
          clearable
          @clear="getPrivateFlowChart"
          @change="getFilterAssetsList"
        >
          <el-option
            v-for="(item,index) in scenceOptions"
            :key="index"
            :label="item.sceneName"
            :value="item.sceneId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="IT资产">
        <el-select
          v-model="form.assetsId"
          placeholder="请选择IT资产"
          clearable
          @clear="getPrivateFlowChart"
        >
          <el-option
            v-for="(item,index) in assestOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="getPrivateFlowChart"
        >
          查询
        </el-button>
      </el-form-item>
    </el-form>
    <div
      class="flow-detail"
      style="height: 100%"
    >
      <div
        v-if="!empty"
        class="flex-start"
      >
        <div class="chart-lengend chart-lengend-1">
          采集
        </div>
        <div class="chart-lengend chart-lengend-2">
          处理
        </div>
        <div class="chart-lengend chart-lengend-3">
          存储
        </div>
        <div class="chart-lengend chart-lengend-4">
          分享
        </div>
        <div class="chart-lengend chart-lengend-5">
          使用
        </div>
      </div>
      <div
        style="opacity: 0"
        class="node-type-wrap"
      >
        <h4
          id="node-type-1"
          class="node-type"
        >
          采集
        </h4>
        <h4
          id="node-type-2"
          class="node-type"
        >
          处理
        </h4>
        <h4
          id="node-type-3"
          class="node-type"
        >
          存储
        </h4>
        <h4
          id="node-type-4"
          class="node-type"
        >
          共享
        </h4>
        <h4
            id="node-type-5"
            class="node-type"
        >
          使用
        </h4>
      </div>
      <div
        v-if="!empty"
        id="flowContent"
        ref="flowContent"
        :style="{height: boxHeight + 'px'}"
        @drop="drop($event)"
        @dragover="allowDrop($event)"
      >
        <flowNode
          v-for="node in nodeList"
          :id="node.id"
          :key="node.id"
          :node="node"
          :isconnect="isConnect"
        />
      </div>
      <el-empty
        v-else
        description="暂无数据"
      />
    </div>
    <LineDialog ref="LineDialog" />
  </div>
</template>

<script>
import { GetFilterAssetsList, GetFilterBusinessList, GetPrivateFlowChart } from '@/api/dataMap/dataMapProcess'
import LineDialog from '@/views/dataIntelligent/dataAtlas/LineDialog.vue'
import { productSceneSelect } from '@/api/enterprise/product'
import flowNode from '@/views/dataIntelligent/dataAtlas/flowNode.vue'

export default {
  components: { LineDialog, flowNode },
  data() {
    return {
      boxHeight: 0,
      scenceOptions: [],
      assestOptions: [],
      maxNodeLen: 0,
      nodeList: [],
      lineList: [],
      jsPlumb: null, // jsPlumb 实例
      index: 1,
      // 默认设置参数
      jsplumbSetting: {
        // 动态锚点、位置自适应
        Anchors: ['Top', 'TopCenter', 'TopRight', 'TopLeft', 'Right', 'RightMiddle', 'Bottom', 'BottomCenter',
          'BottomRight', 'BottomLeft', 'Left', 'LeftMiddle',
        ],
        Container: 'flowContent',
        // 连线的样式 StateMachine、Flowchart,有四种默认类型：Bezier（贝塞尔曲线），Straight（直线），Flowchart（流程图），State machine（状态机）
        Connector: 'Flowchart',
        // Connector: 'Straight',
        // 这个是鼠标拉出来的线的属性
        ConnectionOverlays: [
          ['Label', {
            label: '连线文本',
            id: 'label-1',
            cssClass: 'csslabel',
          }],
        ],
        // 鼠标不能拖动删除线
        ConnectionsDetachable: false,
        // 删除线的时候节点不删除
        DeleteEndpointsOnDetach: false,
        // 连线的端点
        // Endpoint: ["Dot", {radius: 5}],
        Endpoint: ['Rectangle', {
          height: 10,
          width: 10,
        }],
        // 线端点的样式
        EndpointStyle: {
          fill: 'rgba(255,255,255,0)',
          outlineWidth: 1,
        },
        LogEnabled: true, // 是否打开jsPlumb的内部日志记录
        // 绘制线
        PaintStyle: {
          stroke: '#409eff',
          // stroke: '#0d40b2',
          strokeWidth: 2,
        },
        // 绘制箭头
        Overlays: [
          ['Arrow', {
            width: 8,
            length: 8,
            location: 1,
          }],
        ],
        RenderMode: 'svg',
      },
      // jsplumb连接参数
      jsplumbConnectOptions: {
        isSource: true,
        isTarget: true,
        // 动态锚点、提供了4个方向 Continuous、AutoDefault
        anchor: 'Continuous',
      },
      jsplumbSourceOptions: {
        filter: '.flow-node-drag', // 触发连线的区域
        // filter: '.node-name', // 触发连线的区域
        /* "span"表示标签，".className"表示类，"#id"表示元素id */
        filterExclude: false,
        anchor: 'Static',
        // anchor: 'Perimeter',
        allowLoopback: false,
      },
      jsplumbTargetOptions: {
        filter: '.flow-node-drag',
        // filter: '.type-icon', // 触发连线的区域
        /* "span"表示标签，".className"表示类，"#id"表示元素id */
        filterExclude: false,
        anchor: 'Continuous',
        allowLoopback: false,
      },
      // 是否加载完毕
      loadEasyFlowFinish: false,
      isConnect: false, // 判断是否连接

      form: {
        productId: '',
        assetsId: '',
        sceneId: '',
      },
      productOptions: [],
      testData: [
        { id: '1' },
        { id: '2' },
      ],
      testData1: [
        { id: '3' },
        { id: '4' },
      ],
      thridPartyTypeObj: {
        1: '供应商',
        2: '销售代理及渠道',
        3: '原厂商',
        4: '合作单位',
        5: '监管单位',
        6: '其他',
      },
      loading: false,
      listQuery: {
        assetsId: 0,
        fieldId: 0,
        productId: 0,
        thirdPartyId: 0,
      },
      idObj: {
        1: 'collect',
        2: 'dataRun',
        3: 'store',
        4: 'thridParty',
      },
      collectNodes: [],
      dataRunNodes: [],
      storeNodes: [],
      thridPartyNodes: [],
      lines: [],
      dataUseNodes: [],
      linesObj: [],
      domIdType: {
        1: 'data-collect',
        2: 'data-run',
        3: 'data-store',
        4: 'data-thrid',
        5: 'data-use',
      },
      empty: false,
      assestTypeObj: {
        1: '前端应用服务器',
        2: '后端应用服务器',
        3: '中间件',
        4: '数据库',
        5: '文件存储',
        6: '数据备份',
        7: '邮件',
        8: '第三方接口',
        9: '客户端设备',
      },
      colors: [
        '#FFC14B', '#92FFFF', '#ACFF96', '#7583FF', '#73C3FF',
        '#5477FE', '#5F57F9', '#7982FE', '#4F78FE', '#2B9DFF',
        '#61FEFF', '#937FE6', '#2B56D3', '#87E7AA', '#937FE6',
        '#FF9B97', '#8f23f5', '#0576ea', '#2cb8cf', '#8A7EE0',
        '#2cb8cf', '#4e70f0', '#1fa3de', '#bbc951', '#2D9BFF',
        '#b785a6', '#04E0F3', '#682EFC', '#35A7FE', '#0DC09F',
        '#682EFC', '#ED6663', '#8f23f5', '#0576ea', '#2cb8cf',
        '#8A7EE0', '#2cb8cf', '#4e70f0', '#1fa3de', '#bbc951',
        '#b785a6', '#39BFFF', '#76C2FF', '#5F57FC', '#FFC14B',
      ],
    }
  },
  created() {
    this.getAllProduct()
  },
  mounted() {
    this.getPrivateFlowChart()
    // this.jsPlumb = jsPlumb.getInstance()
    // this.$nextTick(() => {
    //   this.init()
    // });
  },
  methods: {
    getFilterAssetsList() {
      this.form.assetsId = ''
      GetFilterAssetsList({ productId: this.form.productId }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.assestOptions = resData.data
        }
      })
    },
    productSceneSelect() {
      productSceneSelect(this.form.productId, 1).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.scenceOptions = resData.data
        }
      })
    },
    productChange() {
      this.form.assetsId = ''
      this.form.sceneId = ''
      this.productSceneSelect()
      this.getFilterAssetsList()
    },
    getAllProduct() {
      GetFilterBusinessList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productOptions = resData.data
        }
      })
    },
    addNode(nodeArr, left, top) {
      nodeArr.forEach((node, index) => {
        const screenW = window.screen.width
        let oldScreenW = 1920
        let l = 0
        if (node.left) {
          oldScreenW = node.left.split('-')[1]
          l = `${(screenW / oldScreenW) * node.left.split('-')[0]}px`
        } else {
          l = `${left - 200}px`
        }
        const t = node.top ? (node.top.indexOf('px') === -1 ? `${node.top}px` : node.top) : `${top + 120 * (index)}px`
        this.nodeList.push({
          id: node.nodeId,
          label: node.name,
          type: node.type,
          value: node.value,
          left: l,
          top: t,
        })
      })
    },
    getPrivateFlowChart() {
      this.loading = true
      const listQuery = {
        productId: this.form.productId ? this.form.productId : 0,
        sceneId: this.form.sceneId ? this.form.sceneId : 0,
        assetsId: this.form.assetsId ? this.form.assetsId : 0,
      }
      this.nodeList = []
      this.lineList = []
      if (this.jsPlumb) {
        this.jsPlumb.deleteEveryConnection()
      }
      GetPrivateFlowChart(listQuery).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          const { nodeList } = resData.data
          const { lineList } = resData.data
          if (nodeList) {
            if (nodeList.length > 0) {
              // 生成dom
              const nodeType1Info = document.getElementById('node-type-1').getBoundingClientRect()
              const nodeType2Info = document.getElementById('node-type-2').getBoundingClientRect()
              const nodeType3Info = document.getElementById('node-type-3').getBoundingClientRect()
              const nodeType4Info = document.getElementById('node-type-4').getBoundingClientRect()
              const nodeType5Info = document.getElementById('node-type-5').getBoundingClientRect()
              const nodeType1Arr = nodeList.filter(item => item.type === 1)
              const nodeType2Arr = nodeList.filter(item => item.type === 2)
              const nodeType3Arr = nodeList.filter(item => item.type === 3)
              const nodeType4Arr = nodeList.filter(item => item.type === 4)
              const nodeType5Arr = nodeList.filter(item => item.type === 5)
              this.addNode(nodeType1Arr, nodeType1Info.x, nodeType1Info.height)
              this.addNode(nodeType2Arr, nodeType2Info.x, nodeType2Info.height)
              this.addNode(nodeType3Arr, nodeType3Info.x, nodeType3Info.height)
              this.addNode(nodeType4Arr, nodeType4Info.x, nodeType4Info.height)
              this.addNode(nodeType5Arr, nodeType5Info.x, nodeType5Info.height)
              // 确定容器高度
              this.maxNodeLen = Math.max(nodeType1Arr.length, nodeType2Arr.length, nodeType3Arr.length, nodeType4Arr.length)
              const topArr = nodeList.map(node => Number(node.top.replace('px', '')))
              const maxTop = Math.max(...topArr)
              if (maxTop) {
                if(maxTop > (this.maxNodeLen + 1) * 150){
                  this.boxHeight = maxTop + 150
                }else{
                  this.boxHeight = (this.maxNodeLen + 1) * 150
                }
              } else {
                this.boxHeight = (this.maxNodeLen + 1) * 150
              }
              console.log(this.nodeList)
              console.log(this.boxHeight)
              const labelObj = {
                1: '采集',
                2: '处理',
                3: '存储',
                4: '分享',
                5: '使用',
              }
              lineList.forEach(line => {
                if (line.source !== line.target) {
                  let sourceLabel = ''
                  let targetLabel = ''
                  this.nodeList.forEach(node => {
                    if (node.id === line.source) {
                      sourceLabel = labelObj[node.type]
                    }
                    if (node.id === line.target) {
                      targetLabel = labelObj[node.type]
                    }
                  })
                  this.lineList.push({
                    from: line.source,
                    sourceType: line.sourceType,
                    targetType: line.targetType,
                    value: line.value,
                    label: `${sourceLabel}-${targetLabel}`,
                    to: line.target,
                  })
                }
              })
              this.jsPlumb = jsPlumb.getInstance()
              this.$nextTick(() => {
                this.init()
              })
              this.empty = false
            } else {
              this.empty = true
            }
          } else {
            this.empty = true
          }
        }
      })
    },
    // 是否具有该线
    hasLine(from, to) {
      for (let i = 0; i < this.lineList.length; i++) {
        const line = this.lineList[i]
        if (line.from === from && line.to === to) {
          return true
        }
      }
      return false
    },
    // 是否含有相反的线
    hashOppositeLine(from, to) {
      return this.hasLine(to, from)
    },
    init() {
      const _this = this
      this.jsPlumb.ready(() => {
        // 导入默认配置
        _this.jsPlumb.importDefaults(_this.jsplumbSetting)
        // 会使整个jsPlumb立即重绘。
        _this.jsPlumb.setSuspendDrawing(false, true)
        // 初始化节点
        _this.loadEasyFlow()
        // 单点连接线
        _this.jsPlumb.bind('click', (conn, originalEvent) => {
          console.log('aaa')
          _this.$refs.LineDialog.dialogVisible = true
          _this.lineList.forEach(line => {
            if (line.from === conn.sourceId && line.to === conn.targetId) {
              _this.$refs.LineDialog.value = line.value
            }
          })
        })
        // 改变线的连接节点
        _this.jsPlumb.bind('connectionMoved', evt => {
          console.log('bbb')
          _this.changeLine(evt.originalSourceId, evt.originalTargetId)
        })

        // 单击endpoint
        jsPlumb.bind('endpointClick', evt => {
          console.log('ccc')
          console.log('endpointClick', evt)
        })
        //
        // // 双击endpoint
        // jsPlumb.bind("endpointDblClick", function (evt) {
        //   console.log('endpointDblClick', evt)
        // })

        // contextmenu 右键
        _this.jsPlumb.bind('contextmenu', evt => {
          console.log('contextmenu', evt)
        })

        // beforeDrop
        _this.jsPlumb.bind('beforeDrop', evt => {
          console.log('aaa')
          const from = evt.sourceId
          const to = evt.targetId
          if (from === to) {
            _this.$message.error('不能连接自己')
            return false
          }
          if (_this.hasLine(from, to)) {
            _this.$message.error('不能重复连线')
            return false
          }
          if (_this.hashOppositeLine(from, to)) {
            _this.$message.error('不能回环哦')
            return false
          }
          return true
        })

        // beforeDetach
        _this.jsPlumb.bind('beforeDetach', evt => {
          console.log('beforeDetach', evt)
        })
      })
    },
    // 加载流程图
    loadEasyFlow() {
      // 初始化节点
      for (var i = 0; i < this.nodeList.length; i++) {
        const node = this.nodeList[i]
        // 设置源点，可以拖出线连接其他节点
        this.jsPlumb.makeSource(node.id, this.jsplumbSourceOptions)

        // // 设置目标点，其他源点拖出的线可以连接该节点
        this.jsPlumb.makeTarget(node.id, this.jsplumbTargetOptions)
        // 设置可拖拽
        // jsPlumb.draggable(node.id, {
        //     containment: 'parent',
        //     grid: [10, 10]
        // })

        this.jsPlumb.draggable(node.id, {
          containment: 'parent',
          // grid: [200, 10]
        })

        // jsPlumb.draggable(node.id)
      }

      // 初始化连线
      for (var i = 0; i < this.lineList.length; i++) {
        const line = this.lineList[i]
        const connection = this.jsPlumb.connect({
          source: line.from,
          target: line.to,
          paintStyle: { stroke: this.colors[Math.floor(Math.random() * 45)], strokeWidth: 2 },
        }, this.jsplumbConnectOptions)

        connection.getOverlay('label-1').setLabel(line.label) // 初始化label
      }
      this.$nextTick(function () {
        this.loadEasyFlowFinish = true
      })
    },
    allowDrop(event) {
      console.log('aaa')
      event.preventDefault()
    },
    drop(event) {
      console.log('bbb')
    },
  },
}
</script>

<style lang="scss">
.lengend-icon{
  font-size: 20px;
  color: #0d40b2;
}
.prover-ul li {
  padding: 0 10px;
  min-height: 32px;
  //line-height: ;
  display: flex;
  align-items: center;
  vertical-align: middle;
  border-bottom: 1px solid #dcdfe6;
  list-style: none;
}
.line-proper {
  width: 240px;
  padding: 0;
  font-size: 12px;
}
.line-item {
  i {
    font-size: 50px;
    color: #0d40b2;
  }
}
.iconfont-label {
  position: relative;
  top: -10px;
  text-align: center;
}
.label-box {
  /*width: 100px;*/
  padding: 0 10px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #0d40b2;
  line-height: 30px;
}
.line-proper span{
  word-break: break-all;
  white-space: pre-wrap;
}
.prover-label{
  font-weight: bold;
  word-break: keep-all !important;
  white-space: nowrap !important;
}

.node-type-wrap{
  display: flex;
  justify-content: flex-start;
}
.node-type{
  flex: 1;
  text-align: center;
}
#flowContent {
  width: 100%;
  //height: 500px;
  position: relative;
}
.csslabel {
  color: #434343;
  font-weight: 400;
  z-index: 10;
  font-size: 12px;
  color: #409eff;
  background: #fff;
}
.csslabel .label-text {
  background-color: white;
}
.chart-lengend{
  position: relative;
  margin-right: 10px;
  text-indent: 20px;
}
.chart-lengend:before{
  position: absolute;
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.chart-lengend-1:before{
  background: #7367f0;
  color: #7367f0;
}
.chart-lengend-2:before{
  background: #f3862d;
  color: #f3862d;
}
.chart-lengend-3:before{
  background: #1dce05;
  color: #1dce05;
}
.chart-lengend-4:before{
  background: #00cfe8;
  color: #00cfe8;
}
.chart-lengend-5:before{
  background: #0d40b2;
  color: #0d40b2;
}
</style>
