<template>
  <el-dialog
    title="传输线路信息"
    :visible.sync="dialogVisible"
    width="620px"
    @open="dialogOpen"
  >
    <el-descriptions  column="1">
      <el-descriptions-item label="传输是否加密">{{ lineInfo.transmissionEncryption }}</el-descriptions-item>
      <el-descriptions-item label="加密方式">{{ lineInfo.transmissionEncryptionMethod }}</el-descriptions-item>
      <el-descriptions-item label="传输方式">{{ lineInfo.transmissionMethod }}</el-descriptions-item>
      <el-descriptions-item label="传输线路">{{ lineInfo.transmissionWay }}</el-descriptions-item>
      <el-descriptions-item label="传输字段">{{ lineInfo.fieldNames }}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
import { GetLineInfo } from '@/api/dataMap/dataMapProcess'

export default {
  name: 'LineDialog',
  data() {
    return {
      dialogVisible: false,
      lineInfo: {
        transmissionEncryption: '', // 传输是否加密
        transmissionEncryptionMethod: '', // 加密方式
        transmissionMethod: '', // 传输方式
        transmissionWay: '', // 传输入线
        fieldNames: '', // 传输字段
      },
      value: '',
    }
  },
  methods: {
    dialogOpen() {
      GetLineInfo(this.value).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.lineInfo = resData.data
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
