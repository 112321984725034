<template>
  <div>
    <el-form
      :inline="true"
      :model="form"
      class="demo-form-inline"
    >
      <el-form-item label="业务系统">
        <el-select
          v-model="form.productId"
          placeholder="请选择业务系统"
          @change="productChange"
          @close="getChartData"
        >
          <el-option
            v-for="(item,index) in productOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="业务场景">
        <el-select
            v-model="form.sceneId"
            placeholder="请选择业务场景"
            clearable
            @close="getChartData"
        >
          <el-option
              v-for="(item,index) in sceneOptions"
              :key="index"
              :label="item.sceneName"
              :value="item.sceneId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="IT资产">
        <el-select
          v-model="form.assetsId"
          placeholder="请选择IT资产"
          clearable
          @close="getChartData"
          @change="getPrivacyFieldOptionsList"
        >
          <el-option
            v-for="(item,index) in assestOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="第三方">
        <el-select
          v-model="form.thirdPartyId"
          placeholder="请选择第三方"
          clearable
          @close="getChartData"
          @change="getPrivacyFieldOptionsList"
        >
          <el-option
            v-for="(item,index) in thirdPartyOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item><!--
      <el-form-item label="隐私字段">
        <el-select
          v-model="form.fieldId"
          placeholder="请选择隐私字段"
          clearable
          @close="getChartData"
        >
          <el-option
            v-for="(item,index) in fieldOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>-->
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="getChartData"
        >
          查询
        </el-button>
      </el-form-item>
    </el-form>
    <div
      id="chart"
      style="width:100%;"
      :style="{height: pageH + 'px'}"
    />
    <el-empty
      v-show="this.lineList.length === 0 && !loading"
      class="chart-empty"
      description="暂无数据"
    />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {
  GetPrivateSankeyChart, GetFilterAssetsList, GetFilterThirdPartyList, GetFilterFieldList, GetFilterBusinessList
} from '@/api/dataMap/dataMapProcess'
import { productSceneSelect } from '@/api/enterprise/product'

export default {
  name: 'Atlas',
  data() {
    return {
      chart: null,
      productOptions: [],
      sceneOptions: [],
      assestOptions: [],
      thirdPartyOptions: [],
      fieldOptions: [],
      loadingStyle: {
        text: '数据正在加载...',
        textColor: '#fff',
        color: '#0d40b2',
        maskColor: 'rgba(255, 255, 255, 0)',
        zlevel: 0,

        // 字体大小。从 `v4.8.0` 开始支持。
        fontSize: 12,
        // 是否显示旋转动画（spinner）。从 `v4.8.0` 开始支持。
        showSpinner: true,
        // 旋转动画（spinner）的半径。从 `v4.8.0` 开始支持。
        spinnerRadius: 10,
        // 旋转动画（spinner）的线宽。从 `v4.8.0` 开始支持。
        lineWidth: 5,
        // 字体粗细。从 `v5.0.1` 开始支持。
        fontWeight: 'normal',
        // 字体风格。从 `v5.0.1` 开始支持。
        fontStyle: 'normal',
        // 字体系列。从 `v5.0.1` 开始支持。
        fontFamily: 'sans-serif',
      },
      form: {
        assetsId: '',
        fieldId: '',
        productId: '',
        thirdPartyId: '',
        sceneId: undefined,
      },
      nodeList: [],
      lineList: [],
      loading: false,
      sysT: {},
      colors: ['#2D9BFF', '#39BFFF', '#76C2FF', '#5F57FC',
        '#2D9BFF', '#92FFFF', '#ACFF96', '#7583FF', '#73C3FF',
        '#5477FE', '#5F57F9', '#7982FE', '#4F78FE', '#2B9DFF',
        '#61FEFF', '#937FE6', '#2B56D3', '#87E7AA', '#937FE6', '#FF9B97', '#8f23f5', '#0576ea', '#2cb8cf', '#8A7EE0', '#2cb8cf', '#4e70f0',
        '#1fa3de', '#bbc951', '#FFC14B', '#b785a6',
        '#04E0F3', '#682EFC', '#35A7FE', '#0DC09F', '#682EFC', '#ED6663', '#8f23f5', '#0576ea',
        '#2cb8cf', '#8A7EE0', '#2cb8cf', '#4e70f0', '#1fa3de', '#bbc951', '#FFC14B', '#b785a6',
      ],
    }
  },
  created() {
    this.getAllProduct()
    // this.getAssetsOptions()
    // this.getThirdPartyOptions()
    // this.getPrivacyFieldOptionsList()
  },
  mounted() {

    // this.getChartData()
  },
  methods: {
    getChartData() {
      this.loading = true
      if (!this.chart) {
        this.chart = echarts.init(document.getElementById('chart'))
        this.chart.showLoading(this.loadingStyle)
      } else {
        this.chart.clear()
      }
      const listQuery = {
        assetsId: this.form.assetsId ? this.form.assetsId : 0,
        fieldId: this.form.fieldId ? this.form.fieldId : 0,
        productId: this.form.productId ? this.form.productId : 0,
        thirdPartyId: this.form.thirdPartyId ? this.form.thirdPartyId : 0,
        sceneId: this.form.sceneId ? this.form.sceneId : 0,
      }
      GetPrivateSankeyChart(listQuery).then(res => {
        this.loading = false
        this.chart.hideLoading()
        if (res.data.code === 0) {
          const { nodeList } = res.data.data
          this.lineList = []
          this.sysT = {}
          if (nodeList) {
            if (nodeList.length > 0) {
              this.lineList = res.data.data.lineList
              // this.lineList.forEach(line => {
              //   if (nodeList.indexOf(line.source) === -1) {
              //     nodeList.push(line.source)
              //   }
              //   if (nodeList.indexOf(line.target) === -1) {
              //     nodeList.push(line.target)
              //   }
              // })
              const colorList = []
              nodeList.forEach((node, index) => {
                if (node) {
                  if (index < this.colors.length - 1) {
                    this.sysT[node.name] = this.colors[index % this.colors.length]
                  } else {
                    this.sysT[node.name] = '#2D9BFF'
                  }
                  colorList.push({
                    name: node.name,
                    depth: node.depth,
                    itemStyle: {
                      color: this.colors[index % this.colors.length],
                      // color: '#0d40b2',
                    },
                  })
                }
              })
              const dataF = []

              // eslint-disable-next-line guard-for-in
              // for (const key in this.sysT) {
              //   colorList.push({
              //     name: key,
              //     itemStyle: {
              //       color: this.sysT[key],
              //       // color: '#0d40b2',
              //     },
              //   })
              // }
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < this.lineList.length; i++) {
                const color = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: this.sysT[this.lineList[i].source],
                  },
                  {
                    offset: 1,
                    color: this.sysT[this.lineList[i].target],
                  },
                ])
                if (this.lineList[i].source !== this.lineList[i].target) {
                  dataF.push({
                    source: this.lineList[i].source,
                    target: this.lineList[i].target,
                    // value: this.lineList[i].value,
                    value: 2,
                    lineStyle: {
                      // color: '#0d40b2'
                      // color: color
                    },
                  })
                }
              }
              const option = {
                series: [
                  {
                    type: 'sankey',
                    data: colorList,
                    // nodeWidth: 80,
                    // nodeHidth: 40,
                    // nodeGap: 40,
                    dataZoom: { // 放大和缩放
                      type: 'inside',
                    },
                    toolbox: {
                      show: true,
                    },
                    layoutIterations: 0,
                    silent: false, // 是否响应鼠标事件  true不响应
                    links: dataF,
                    focusNodeAdjacency: 'allEdges',
                    // levels: [{
                    //   depth: 1,
                    //   itemStyle: {
                    //     color: '#fbb4ae'
                    //   },
                    //   lineStyle: {
                    //     color: 'red',
                    //     opacity: 1
                    //   }
                    // }],
                    itemStyle: {
                      borderWidth: 0,
                    },
                    // label: {
                    //   // color: '#606266',
                    //   fontSize: 12,
                    // },
                    label: {
                      normal: {
                        fontSize: 12,
                        // align: "center",
                        // padding: 20,
                        // position: 'bottom',
                        // color: "#fff",
                      },
                    },
                    lineStyle: {
                      color: 'source',
                      curveness: 0.2,
                      opacity: 0.2,
                    },
                  },
                ],
              }
              this.chart.setOption(option)
            }
          }
        }
      })
    },
    productChange() {
      this.getFilterAssetsList()
      this.getThirdPartyOptions()
      this.getPrivacyFieldOptionsList()
      this.getChartData()
      this.productSceneSelect()
    },
    getFilterAssetsList() {
      this.form.assetsId = ''
      GetFilterAssetsList({ productId: this.form.productId }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.assestOptions = resData.data
        }
      })
    },
    getAllProduct() {
      GetFilterBusinessList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productOptions = resData.data
          if (this.productOptions.length > 0) {
            this.form.productId = this.productOptions[0].id
            this.productSceneSelect()
            this.getFilterAssetsList()
            this.getThirdPartyOptions()
            this.getChartData()
          }
        }
      })
    },
    getThirdPartyOptions() {
      GetFilterThirdPartyList({ productId: this.form.productId }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.thirdPartyOptions = resData.data
        }
      })
    },
    getPrivacyFieldOptionsList() {
      const searchObj = {
        assetsId: this.form.assetsId ? this.form.assetsId : 0,
        productId: this.form.productId ? this.form.productId : 0,
        thirdPartyId: this.form.thirdPartyId ? this.form.thirdPartyId : 0,
        sceneId: this.form.sceneId ? this.form.sceneId : 0,
      }
      GetFilterFieldList(searchObj).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          if (resData.data) {
            this.fieldOptions = resData.data
          }
        }
      })
    },
    productSceneSelect() {
      productSceneSelect(this.form.productId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.sceneOptions = resData.data
        }
      })
    },
  },
}
</script>

<style >
  .chart-empty{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
