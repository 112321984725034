<template>
  <div v-loading="loading">
    <el-form
      :inline="true"
      :model="listQuery"
      class="demo-form-inline"
    >
      <el-form-item label="业务系统">
        <el-select
          v-model="listQuery.productId"
          placeholder="请选择业务系统"
          clearable
          @close="getPrivateConsanguinityChart"
        >
          <el-option
            v-for="(item,index) in productOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="隐私字段">
        <el-select
          v-model="listQuery.fieldId"
          placeholder="请选择隐私字段"
          clearable
          @close="getPrivateConsanguinityChart"
        >
          <el-option
            v-for="(item,index) in fieldOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="getPrivateConsanguinityChart"
        >
          查询
        </el-button>
      </el-form-item>
    </el-form>
    <div
        style="opacity: 0"
        class="node-type-wrap"
    >
      <h4
          id="node-type-6"
          class="node-type"
      >
        采集
      </h4>
      <h4
          id="node-type-1"
          class="node-type"
      >
        采集
      </h4>
      <h4
          id="node-type-2"
          class="node-type"
      >
        处理
      </h4>
      <h4
          id="node-type-3"
          class="node-type"
      >
        存储
      </h4>
      <h4
          id="node-type-4"
          class="node-type"
      >
        共享
      </h4>
      <h4
          id="node-type-5"
          class="node-type"
      >
        使用
      </h4>
    </div>
    <div
        id="flowContent"
        ref="flowContent"
        :style="{height: boxHeight + 'px'}"
        @drop="drop($event)"
        @dragover="allowDrop($event)"
    >
      <BloodMapFlowNode
          v-for="node in nodeList"
          :id="node.id"
          :key="node.id"
          :node="node"
          :isconnect="isConnect"
      />
    </div>
    <LineDialog ref="LineDialog" />
  </div>
</template>

<script>
import {
  GetPrivateConsanguinityChart,
  GetFilterBusinessList,
  GetFilterFieldList,
} from '@/api/dataMap/dataMapProcess'
import LineDialog from '@/views/dataIntelligent/dataAtlas/LineDialog.vue'
import BloodMapFlowNode from '@/views/dataIntelligent/dataAtlas/BloodMapFlowNode.vue'

export default {
  components: { LineDialog, BloodMapFlowNode },
  data() {
    return {
      boxHeight: 0,
      maxNodeLen: 0,
      nodeList: [],
      jsPlumb: null, // jsPlumb 实例
      index: 1,
      // 默认设置参数
      jsplumbSetting: {
        // 动态锚点、位置自适应
        Anchors: ['Top', 'TopCenter', 'TopRight', 'TopLeft', 'Right', 'RightMiddle', 'Bottom', 'BottomCenter',
          'BottomRight', 'BottomLeft', 'Left', 'LeftMiddle',
        ],
        Container: 'flowContent',
        // 连线的样式 StateMachine、Flowchart,有四种默认类型：Bezier（贝塞尔曲线），Straight（直线），Flowchart（流程图），State machine（状态机）
        Connector: 'Flowchart',
        // Connector: 'Straight',
        // 这个是鼠标拉出来的线的属性
        ConnectionOverlays: [
          ['Label', {
            label: '连线文本',
            id: 'label-1',
            cssClass: 'csslabel',
          }],
        ],
        // 鼠标不能拖动删除线
        ConnectionsDetachable: false,
        // 删除线的时候节点不删除
        DeleteEndpointsOnDetach: false,
        // 连线的端点
        // Endpoint: ["Dot", {radius: 5}],
        Endpoint: ['Rectangle', {
          height: 10,
          width: 10,
        }],
        // 线端点的样式
        EndpointStyle: {
          fill: 'rgba(255,255,255,0)',
          outlineWidth: 1,
        },
        LogEnabled: true, // 是否打开jsPlumb的内部日志记录
        // 绘制线
        PaintStyle: {
          stroke: '#409eff',
          // stroke: '#0d40b2',
          strokeWidth: 2,
        },
        // 绘制箭头
        Overlays: [
          ['Arrow', {
            width: 8,
            length: 8,
            location: 1,
          }],
        ],
        RenderMode: 'svg',
      },
      // jsplumb连接参数
      jsplumbConnectOptions: {
        isSource: true,
        isTarget: true,
        // 动态锚点、提供了4个方向 Continuous、AutoDefault
        anchor: 'Continuous',
      },
      jsplumbSourceOptions: {
        filter: '.flow-node-drag', // 触发连线的区域
        // filter: '.node-name', // 触发连线的区域
        /* "span"表示标签，".className"表示类，"#id"表示元素id */
        filterExclude: false,
        anchor: 'Static',
        // anchor: 'Perimeter',
        allowLoopback: false,
      },
      jsplumbTargetOptions: {
        filter: '.flow-node-drag',
        // filter: '.type-icon', // 触发连线的区域
        /* "span"表示标签，".className"表示类，"#id"表示元素id */
        filterExclude: false,
        anchor: 'Continuous',
        allowLoopback: false,
      },
      // 是否加载完毕
      loadEasyFlowFinish: false,
      isConnect: false, // 判断是否连接

      loading: true,
      testData: [
        { id: '1' },
        { id: '2' },
      ],
      listQuery: {
        fieldId: '',
        productId: '',
      },
      productOptions: [],
      assestOptions: [],
      thirdPartyOptions: [],
      fieldOptions: [],
      lineList: [],
      graph: null,
      width: document.body.clientWidth - 360,
      height: document.body.clientHeight - 240,
      colors: [
        '#FFC14B', '#92FFFF', '#ACFF96', '#7583FF', '#73C3FF',
        '#5477FE', '#5F57F9', '#7982FE', '#4F78FE', '#2B9DFF',
        '#61FEFF', '#937FE6', '#2B56D3', '#87E7AA', '#937FE6',
        '#FF9B97', '#8f23f5', '#0576ea', '#2cb8cf', '#8A7EE0',
        '#2cb8cf', '#4e70f0', '#1fa3de', '#bbc951', '#2D9BFF',
        '#b785a6', '#04E0F3', '#682EFC', '#35A7FE', '#0DC09F',
        '#682EFC', '#ED6663', '#8f23f5', '#0576ea', '#2cb8cf',
        '#8A7EE0', '#2cb8cf', '#4e70f0', '#1fa3de', '#bbc951',
        '#b785a6', '#39BFFF', '#76C2FF', '#5F57FC', '#FFC14B',
      ],
    }
  },
  mounted() {
    this.getAllProduct()
  },
  methods: {
    addNode(nodeArr, left, top) {
      nodeArr.forEach((node, index) => {
        const screenW = window.screen.width
        let oldScreenW = 1920
        let l = 0
        if (node.left) {
          oldScreenW = node.left.split('-')[1]
          l = `${(screenW / oldScreenW) * node.left.split('-')[0]}px`
        } else {
          l = `${left - 200}px`
        }
        const t = node.top ? (node.top.indexOf('px') === -1 ? `${node.top}px` : node.top) : `${top + 120 * (index)}px`
        this.nodeList.push({
          id: node.nodeId,
          label: node.name,
          type: node.type,
          value: node.value,
          left: l,
          top: t,
        })
      })
    },
    getPrivateConsanguinityChart() {
      const searchObj = {
        fieldId: this.listQuery.fieldId ? this.listQuery.fieldId : 0,
        // fieldId: 0,
        productId: this.listQuery.productId ? this.listQuery.productId : 0,
        // productId: 0,
      }
      this.loading = true
      this.nodeList = []
      this.lineList = []
      if (this.jsPlumb) {
        this.jsPlumb.deleteEveryConnection()
      }
      GetPrivateConsanguinityChart(searchObj).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          const { nodeList } = resData.data
          const { lineList } = resData.data
          if (nodeList) {
            if (nodeList.length > 0) {
              // 生成dom
              const nodeType1Info = document.getElementById('node-type-1').getBoundingClientRect()
              const nodeType2Info = document.getElementById('node-type-2').getBoundingClientRect()
              const nodeType3Info = document.getElementById('node-type-3').getBoundingClientRect()
              const nodeType4Info = document.getElementById('node-type-4').getBoundingClientRect()
              const nodeType5Info = document.getElementById('node-type-5').getBoundingClientRect()
              const nodeType6Info = document.getElementById('node-type-6').getBoundingClientRect()
              const nodeType1Arr = nodeList.filter(item => item.type === 1)
              const nodeType2Arr = nodeList.filter(item => item.type === 2)
              const nodeType3Arr = nodeList.filter(item => item.type === 3)
              const nodeType4Arr = nodeList.filter(item => item.type === 4)
              const nodeType5Arr = nodeList.filter(item => item.type === 5)
              const nodeType6Arr = nodeList.filter(item => item.type === 6)
              this.addNode(nodeType1Arr, nodeType1Info.x, nodeType1Info.height)
              this.addNode(nodeType2Arr, nodeType2Info.x, nodeType2Info.height)
              this.addNode(nodeType3Arr, nodeType3Info.x, nodeType3Info.height)
              this.addNode(nodeType4Arr, nodeType4Info.x, nodeType4Info.height)
              this.addNode(nodeType5Arr, nodeType5Info.x, nodeType5Info.height)
              this.addNode(nodeType6Arr, nodeType6Info.x, nodeType6Info.height)
              // 确定容器高度
              this.maxNodeLen = Math.max(nodeType1Arr.length, nodeType2Arr.length, nodeType3Arr.length, nodeType4Arr.length,nodeType5Arr.length,nodeType6Arr.length)
              const topArr = nodeList.map(node => Number(node.top.replace('px', '')))
              const maxTop = Math.max(...topArr)
              if (maxTop) {
                if(maxTop > (this.maxNodeLen + 1) * 150){
                  this.boxHeight = maxTop + 150
                }else{
                  this.boxHeight = (this.maxNodeLen + 1) * 150
                }
              } else {
                this.boxHeight = (this.maxNodeLen + 1) * 150
              }
              lineList.forEach(line => {
                if (line.source !== line.target) {
                  // let sourceLabel = ''
                  // let targetLabel = ''
                  // this.nodeList.forEach(node => {
                  //   if (node.id === line.source) {
                  //     sourceLabel = node.label
                  //   }
                  //   if (node.id === line.target) {
                  //     targetLabel = node.label
                  //   }
                  // })
                  this.lineList.push({
                    from: line.source,
                    sourceType: line.sourceType,
                    targetType: line.targetType,
                    value: line.value,
                    label: '',
                    to: line.target,
                  })
                }
              })
              this.jsPlumb = jsPlumb.getInstance()
              this.$nextTick(() => {
                this.init()
              })
              this.empty = false
            } else {
              this.empty = true
            }
          } else {
            this.empty = true
          }
        }
      })
    },
    getAllProduct() {
      GetFilterBusinessList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productOptions = resData.data
          this.listQuery.productId = this.productOptions.length > 0 ? this.productOptions[0].id : 0
          this.getPrivacyFieldOptionsList()
        }
      })
    },
    getPrivacyFieldOptionsList() {
      GetFilterFieldList({
        productId: this.listQuery.productId,
      }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          if (resData.data) {
            this.fieldOptions = resData.data
            if (this.fieldOptions.length > 0) {
              this.listQuery.fieldId = this.fieldOptions[0].id
            } else {
              this.listQuery.fieldId = ''
            }

            this.getPrivateConsanguinityChart()
          }
        }
      })
    },
    // 是否具有该线
    hasLine(from, to) {
      for (let i = 0; i < this.lineList.length; i++) {
        const line = this.lineList[i]
        if (line.from === from && line.to === to) {
          return true
        }
      }
      return false
    },
    // 是否含有相反的线
    hashOppositeLine(from, to) {
      return this.hasLine(to, from)
    },
    init() {
      const _this = this
      this.jsPlumb.ready(() => {
        // 导入默认配置
        _this.jsPlumb.importDefaults(_this.jsplumbSetting)
        // 会使整个jsPlumb立即重绘。
        _this.jsPlumb.setSuspendDrawing(false, true)
        // 初始化节点
        _this.loadEasyFlow()
        // 单点连接线
        _this.jsPlumb.bind('click', (conn, originalEvent) => {
          _this.$refs.LineDialog.dialogVisible = true
          _this.lineList.forEach(line => {
            if (line.from === conn.sourceId && line.to === conn.targetId) {
              _this.$refs.LineDialog.value = line.value
            }
          })
        })
        // 改变线的连接节点
        _this.jsPlumb.bind('connectionMoved', evt => {
          _this.changeLine(evt.originalSourceId, evt.originalTargetId)
        })

        // 单击endpoint
        jsPlumb.bind('endpointClick', evt => {
        })
        //
        // // 双击endpoint
        // jsPlumb.bind("endpointDblClick", function (evt) {
        //   console.log('endpointDblClick', evt)
        // })

        // contextmenu 右键
        _this.jsPlumb.bind('contextmenu', evt => {
          console.log('contextmenu', evt)
        })

        // beforeDrop
        _this.jsPlumb.bind('beforeDrop', evt => {
          const from = evt.sourceId
          const to = evt.targetId
          if (from === to) {
            _this.$message.error('不能连接自己')
            return false
          }
          if (_this.hasLine(from, to)) {
            _this.$message.error('不能重复连线')
            return false
          }
          if (_this.hashOppositeLine(from, to)) {
            _this.$message.error('不能回环哦')
            return false
          }
          return true
        })

        // beforeDetach
        _this.jsPlumb.bind('beforeDetach', evt => {

        })
      })
    },
    // 加载流程图
    loadEasyFlow() {
      // 初始化节点
      for (var i = 0; i < this.nodeList.length; i++) {
        const node = this.nodeList[i]
        // 设置源点，可以拖出线连接其他节点
        this.jsPlumb.makeSource(node.id, this.jsplumbSourceOptions)

        // // 设置目标点，其他源点拖出的线可以连接该节点
        this.jsPlumb.makeTarget(node.id, this.jsplumbTargetOptions)
        // 设置可拖拽
        // jsPlumb.draggable(node.id, {
        //     containment: 'parent',
        //     grid: [10, 10]
        // })

        this.jsPlumb.draggable(node.id, {
          containment: 'parent',
          // grid: [200, 10]
        })

        // jsPlumb.draggable(node.id)
      }

      // 初始化连线
      for (var i = 0; i < this.lineList.length; i++) {
        const line = this.lineList[i]
        const connection = this.jsPlumb.connect({
          source: line.from,
          target: line.to,
          paintStyle: { stroke: this.colors[Math.floor(Math.random() * 45)], strokeWidth: 2 },
        }, this.jsplumbConnectOptions)
        connection.getOverlay('label-1').setLabel(line.label) // 初始化label
      }
      this.$nextTick(function () {
        this.loadEasyFlowFinish = true
      })
    },
    allowDrop(event) {
      event.preventDefault()
    },
    drop(event) {
      console.log('bbb')
    },
  },
}
</script>

<style lang="scss">
.field-wrap {
  padding: 0 20px;
}
.field-text {
  color: #0d40b2;
  margin-bottom: 20px;
  width: fit-content;
  /*width: 40px;*/
  /*height: 10px;*/
}
.chart-item {
  display: flex;
  flex-direction: column;
  /*width: 100px;*/
  width: fit-content;
  height: 100px;
  justify-content: center;
  align-items: center;
  i {
    font-size: 50px;
    color: #0d40b2;
  }
  margin: 0 20px 20px 20px;
}
.target-wrap {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
</style>
