<template>
  <div v-loading="loading">
    <el-form
      :inline="true"
      :model="listQuery"
      class="demo-form-inline"
    >
      <el-form-item label="业务系统">
        <el-select
          v-model="listQuery.productId"
          placeholder="请选择业务系统"
          clearable
          @close="getPrivateConsanguinityChart"
        >
          <el-option
            v-for="(item,index) in productOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="隐私字段">
        <el-select
          v-model="listQuery.fieldId"
          placeholder="请选择隐私字段"
          clearable
          @close="getPrivateConsanguinityChart"
        >
          <el-option
            v-for="(item,index) in fieldOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="getPrivateConsanguinityChart"
        >
          查询
        </el-button>
      </el-form-item>
    </el-form>
    <div
      v-show="lineList.length > 0"
      style="overflow:auto"
    >
      <div
        id="chart"
        :style="{width: width + 'px', height: height + 'px'}"
      />
    </div>
    <el-empty
      v-show="lineList.length === 0"
      description="暂无数据"
    />
    <LineDialog ref="LineDialog" />
  </div>
</template>

<script>
import {
  GetPrivateConsanguinityChart,
  GetFilterBusinessList,
  GetFilterFieldList,
} from '@/api/dataMap/dataMapProcess'
import LineDialog from '@/views/dataIntelligent/dataAtlas/LineDialog.vue'
import G6 from '@antv/g6'

export default {
  components: { LineDialog },
  data() {
    return {
      loading: true,
      testData: [
        { id: '1' },
        { id: '2' },
      ],
      listQuery: {
        fieldId: '',
        productId: '',
      },
      productOptions: [],
      assestOptions: [],
      thirdPartyOptions: [],
      fieldOptions: [],
      lineList: [],
      nodeList: [],
      graph: null,
      width: document.body.clientWidth - 360,
      height: document.body.clientHeight - 240,
      colors: [
        '#FFC14B', '#92FFFF', '#ACFF96', '#7583FF', '#73C3FF',
        '#5477FE', '#5F57F9', '#7982FE', '#4F78FE', '#2B9DFF',
        '#61FEFF', '#937FE6', '#2B56D3', '#87E7AA', '#937FE6',
        '#FF9B97', '#8f23f5', '#0576ea', '#2cb8cf', '#8A7EE0',
        '#2cb8cf', '#4e70f0', '#1fa3de', '#bbc951', '#2D9BFF',
        '#b785a6', '#04E0F3', '#682EFC', '#35A7FE', '#0DC09F',
        '#682EFC', '#ED6663', '#8f23f5', '#0576ea', '#2cb8cf',
        '#8A7EE0', '#2cb8cf', '#4e70f0', '#1fa3de', '#bbc951',
        '#b785a6', '#39BFFF', '#76C2FF', '#5F57FC', '#FFC14B',
      ],
    }
  },
  mounted() {
    this.getAllProduct()
  },
  methods: {
    getPrivateConsanguinityChart() {
      const searchObj = {
        fieldId: this.listQuery.fieldId ? this.listQuery.fieldId : 0,
        productId: this.listQuery.productId ? this.listQuery.productId : 0,
      }
      this.loading = true
      GetPrivateConsanguinityChart(searchObj).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          const tooltip = new G6.Tooltip({
            offsetX: 10,
            offsetY: 20,
            getContent(e) {
              const outDiv = document.createElement('div')
              outDiv.innerHTML = `<h4 style="margin:0;">${e.item.getModel().label}</h4>`
              return outDiv
            },
            itemTypes: ['node'],
          })
          const shortenString = (str, maxWidth) => {
            if (str.length > maxWidth) {
              return `${str.substr(0, maxWidth)}...`
            }
            return str
          }
          this.lineList = resData.data.lineList ? resData.data.lineList : []
          resData.data.nodeList = resData.data.nodeList ? resData.data.nodeList : []
          this.nodeList = []
          let depths = new Set(); let count1 = 0; let
            count2 = 0
          resData.data.nodeList.forEach(node => {
            if (node.depth === 2) {
              node.depth = 9
            }
            depths.add(node.depth)
            if (node.depth === 1) {
              count1++
            }
          })
          depths = Array.from(depths).sort((a, b) => a - b)
          for (let i = 0; i < depths.length; i++) {
            const nodes = resData.data.nodeList.filter(n => n.depth === depths[i])
            for (const [index, node] of nodes.entries()) {
              let config = {}
              if (i) {
                config = {
                  // id: node.name,
                  id: node.nodeId,
                  img: '',
                  label: node.name,
                  value: node.value,
                  x: 100 + (170 * i),
                  y: (i % 2 ? 100 : 300) + (index * 120),
                }
                switch (node.depth) {
                  case 3:
                    config.img = require('@/assets/images/icons/map/web.png')
                    break
                  case 5:
                    config.img = require('@/assets/images/icons/map/serve.png')
                    break
                  case 6:
                    config.img = require('@/assets/images/icons/map/database.png')
                    break
                  case 9:
                    config.img = require('@/assets/images/icons/map/app.png')
                    break
                  case 10:
                    config.img = require('@/assets/images/icons/map/third.png')
                    break
                  default:
                    config.img = require('@/assets/images/icons/map/part.png')
                }
                count2 = Math.max(count2, nodes.length)
              } else {
                config = {
                  // id: node.name,
                  id: node.nodeId,
                  type: 'modelRect',
                  size: [75, 25],
                  logoIcon: {
                    show: false,
                  },
                  stateIcon: {
                    show: false,
                  },
                  labelCfg: {
                    offset: 10,
                    style: {
                      fontSize: 10,
                    },
                  },
                  style: {
                    fill: '#fff',
                  },
                  label: shortenString(node.name, 6),
                  value: node.value,
                  x: 70,
                  y: 30 + (index * 40),
                }
                count1 = nodes.length
              }
              this.nodeList.push(config)
            }
          }
          this.height = Math.max(this.height, count1 * 40 + 40, count2 * 120 + 300)
          this.width = Math.max(this.width, 15 + (170 * depths.length))
          if (this.graph) {
            this.graph.destroy()
          }
          this.graph = new G6.Graph({
            container: 'chart',
            width: this.width,
            height: this.height,
            animate: true,
            groupByTypes: false,
            renderer: 'svg',
            plugins: [tooltip],
            defaultNode: {
              size: 50,
              type: 'image',
              labelCfg: {
                position: 'bottom',
                style: {
                  fontSize: 12,
                },
              },
              anchorPoints: [
                [0, 0.5],
                [1, 0.5],
              ],
            },
            defaultEdge: {
              type: 'polyline',
              style: {
                startArrow: {
                  path: G6.Arrow.circle(-1, 0),
                },
                endArrow: {
                  path: G6.Arrow.triangle(2, -4, 0),
                },
                offset: 50,
                radius: 5,
                lineWidth: 2,
                fontSize: 12,
                stroke: '#0d40b2',
              },
            },
          })
          for (const line of this.lineList) {
            line.style = { stroke: this.colors[Math.floor(Math.random() * 45)] }
            line.type = 'polyline'
          }
          this.graph.data({
            nodes: this.nodeList,
            edges: this.lineList,
          })
          setTimeout(() => {
            this.graph.render()
          }, 100)
          this.graph.on('edge:mouseenter', evt => {
            const { item } = evt
            const edges = this.graph.getEdges()
            edges.forEach(edge => {
              if (edge === item) {
                return
              }
              this.graph.hideItem(edge)
            })
          })
          this.graph.on('edge:mouseleave', evt => {
            const { item } = evt
            const edges = this.graph.getEdges()
            edges.forEach(edge => {
              if (edge === item) {
                return
              }
              this.graph.showItem(edge)
            })
          })
          this.graph.on('node:mouseenter', evt => {
            const item = evt.item._cfg.edges
            const edges = this.graph.getEdges()
            edges.forEach(edge => {
              if (edge === item.find(e => e._cfg.id === edge._cfg.id)) {
                return
              }
              this.graph.hideItem(edge)
            })
          })
          this.graph.on('node:mouseleave', evt => {
            const item = evt.item._cfg.edges
            const edges = this.graph.getEdges()
            edges.forEach(edge => {
              if (edge === item.find(e => e._cfg.id === edge._cfg.id)) {
                return
              }
              this.graph.showItem(edge)
            })
          })
          this.graph.on('edge:click', evt => {
            const { value } = evt.item._cfg.model
            this.$refs.LineDialog.value = value
            this.$refs.LineDialog.dialogVisible = true
          })
        }
      })
    },
    getAllProduct() {
      GetFilterBusinessList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productOptions = resData.data
          this.listQuery.productId = this.productOptions.length > 0 ? this.productOptions[0].id : 0
          this.getPrivacyFieldOptionsList()
        }
      })
    },
    getPrivacyFieldOptionsList() {
      GetFilterFieldList({
        productId: this.listQuery.productId,
      }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          if (resData.data) {
            this.fieldOptions = resData.data
            if (this.fieldOptions.length > 0) {
              this.listQuery.fieldId = this.fieldOptions[0].id
            } else {
              this.listQuery.fieldId = ''
            }

            this.getPrivateConsanguinityChart()
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
.field-wrap {
  padding: 0 20px;
}
.field-text {
  color: #0d40b2;
  margin-bottom: 20px;
  width: fit-content;
  /*width: 40px;*/
  /*height: 10px;*/
}
.chart-item {
  display: flex;
  flex-direction: column;
  /*width: 100px;*/
  width: fit-content;
  height: 100px;
  justify-content: center;
  align-items: center;
  i {
    font-size: 50px;
    color: #0d40b2;
  }
  margin: 0 20px 20px 20px;
}
.target-wrap {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
</style>
