<template>
  <div>
    <PageIntroduce
      :title="'数据图谱'"
      :desc="'基于数据智能发现对企业业务中的个人信息进行全面展示。'"
    />
    <el-tabs
      v-model="activeName"
      type="border-card"
    >
      <!--      <el-tab-pane v-for="(item,index) in navMenus" :label="item.label"-->
      <!--                   :lazy="true"-->
      <!--                   :name="String(index+1)">-->
      <!--        <Atlas v-if="item.label === '数据关联图'" />-->
      <!--        <DataMapChart v-if="item.label === '数据流转图'"/>-->
      <!--        <BloodMap v-if="item.label === '数据血缘图'"/>-->
      <!--      </el-tab-pane>-->
      <el-tab-pane
        label="数据关联图"
        :lazy="true"
        name="1"
      >
        <Atlas v-if="activeName==1" />
      </el-tab-pane>
      <el-tab-pane
        label="数据流转图"
        :lazy="true"
        name="2"
      >
        <DataMapChart v-if="activeName==2" />
      </el-tab-pane>
      <el-tab-pane
        label="数据血缘图"
        :lazy="true"
        name="3"
      >
        <BloodMap v-if="activeName==3" />
      </el-tab-pane>
<!--      <el-tab-pane
          label="数据血缘图2"
          :lazy="true"
          name="4"
      >
        <BloodMap2 v-if="activeName==4" />
      </el-tab-pane>-->
    </el-tabs>
  </div>

</template>

<script>
import Atlas from '@/views/dataIntelligent/dataAtlas/Atlas.vue'
import BloodMap from '@/views/dataIntelligent/dataAtlas/BloodMap.vue'
import BloodMap2 from '@/views/dataIntelligent/dataAtlas/BloodMap2.vue'
// import DataMapChart from '@/views/dataIntelligent/dataAtlas/dataMapChart/DataMapChart6.vue'
import DataMapChart from '@/views/dataIntelligent/dataAtlas/DataMapChart.vue'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'

export default {
  name: 'DataAtlas',
  components: {
    Atlas, DataMapChart, PageIntroduce, BloodMap,BloodMap2
  },
  data() {
    return {
      activeName: '1',
      navMenus: [],
      fullMenus: ['数据关联图', '数据流转图', '数据血缘图'],
    }
  },
  watch: {
    activeName() {
      sessionStorage.setItem('activeName', this.activeName)
    },
  },
  created() {
    const twoLevelMenus = localStorage.getItem('twoLevelMenus')
    if (twoLevelMenus) {
      const menus = JSON.parse(twoLevelMenus)
      menus.forEach(d => {
        if (this.fullMenus.indexOf(d.name) !== -1) {
          this.navMenus.push({
            label: d.name,
          })
        }
      })
    }
    this.activeName = sessionStorage.getItem('activeName') || '1'
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.setItem('activeName', '1')
    next()
  },
}
</script>
